export default {

    computed: {

        merchant() {

            return this.$store.getters.merchant
        }
    }
}
