<script type="text/ecmascript-6">
    import MainHeader from '@/layouts/main/MainHeader.vue'
    import MainFooter from '@/layouts/main/MainFooter.vue'
    export default {
        components: {MainHeader, MainFooter},
        props: {
            currentStep: {

                type: Number,
                default: 0
            }
        },
    }
</script>
<template>
    <div>
        <main-header :currentStep="currentStep" />
        <div class="container" style="min-height: 400px;">
            <slot/>
        </div>
        <main-footer />
    </div>
</template>
