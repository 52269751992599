<script type="text/ecmascript-6">
    import AuthLayout from '@/layouts/AuthLayout.vue'

    export default {

        components: {AuthLayout},
        data() {

            return {

                error: null,
                username: null,
                password: null
            }
        },
        methods: {
            async submitLogin() {

                try {
    
                    await this.$store.dispatch('login', {username: this.username, password: this.password})

                    this.$router.push({name: 'home'})
                }
                catch (error) {

                    this.error = 'Impossible de se connecter'
                }
            },
        },
        created() {

            this.$tracking.pageView('Login', 'Auth')
        }
    }
</script>
<template>
    <auth-layout>
        <div v-if="error" class="alert alert-primary" role="alert">
            {{error}}
        </div>
        <form action="#" @submit.prevent="submitLogin">
            <div class="form-group">
                <input required type="text" v-model="username" placeholder="Login" class="form-control w-100">
            </div>
            <div class="form-group mt-2">
                <input required type="password" v-model="password" placeholder="Mot de passe" class="form-control w-100">
            </div>
            <div class="form-group mt-2">
                <button class="btn btn-success w-100" type="submit">Se connecter</button>
            </div>
        </form>
    </auth-layout>
</template>
