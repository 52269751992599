import orderService from '@/services/order'
import * as Sentry from "@sentry/vue";

export default {

    data() {
        return {
            order: null
        }
    },
    created() {
      
        this.loadOrder(this.$route.params.id)
    },
    methods: {

        async loadOrder(orderPublicId) {

            try {

                let response = await orderService.get(orderPublicId);
                this.order = response.data
            }
            catch (error) {

                Sentry.captureException(error)
                this.$router.push({name: 'home'})
            }
        },
    },
    computed: {

        isOrderLoaded() {

            return this.order != null
        },
        orderId() {

            return this.$route.params.id
        }
    },
    watch: {

        isOrderLoaded() {

            let routeName = ''

            if (this.order.order_status_id == 1) {

                routeName = 'orderTransfer'
            }
            else if (this.order.order_status_id == 2 && (this.order.has_coupon == false || this.$route.query.f == 1)) {

                routeName = 'orderCoupon'
            }
            else if (this.order.order_status_id == 2 && this.order.has_coupon == true) {

                routeName = 'orderDelivery'
            }
            else {

                routeName = 'home'
            }
            
            if (routeName != '' && routeName != this.$route.name) {

                this.$router.push({name: routeName, query: this.$route.query})
            }
        }
    }
}
