<script type="text/ecmascript-6">

import MainLayout from '@/layouts/MainLayout.vue'

import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import mediaMixin from '@/mixins/media'

import orderService from '@/services/order'

import AcceptedOffer from '@/components/AcceptedOffer.vue'
import ProductCard from '@/components/ProductCard.vue'

export default {

    mixins: [orderMixin, merchantMixin, mediaMixin],
    components: {MainLayout, AcceptedOffer, ProductCard},
    watch: {

        isOrderLoaded(isOrderLoaded) {

            if (isOrderLoaded === true) {

                this.createOrderParcel()
                this.$tracking.pageView('Delivery', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
            }
        }
    },
    data() {

        return {

            isLoading: false,
            parcel: null
        }
    },
    methods: {

        async printDeliveryNote() {

            this.isLoading = true
            try {

                let response = await orderService.getDeliveryNote(this.order.public_id)
                this.openFile(response.data, 'pdf')   
            }
            finally {

                this.isLoading = false;
            }
        },
        goToHome() {

            this.$router.push({name: 'home'})
        },
        async createOrderParcel() {

            let response =  await orderService.createOrderParcel(this.order.public_id)
            this.parcel = response.data;
        }
    },
    computed: {

        parcelNbr() {

            if (this.isOrderLoaded == false) return ''

            return this.parcel.pickup.in_store_nbr + '-' + this.parcel.store_parcel_nbr
        }
    }
}
</script>
<template>
    <main-layout :currentStep="60">
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11 mb-4">
                        <h3>Logistique</h3>
                        <ol>
                            <li><button :disabled="isLoading" class="btn btn-info" @click="printDeliveryNote">Imprimer l'étiquette de transport <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></button></li>
                            <li>Mettre le produit dans une protection correspondante et y attacher l'étiquette de transport</li>
                            <li v-if="parcel && parcel.nbr_products == 1">Faire un nouveau carton de type "{{ parcel.type.name }}" et y inscrire le numéro "<strong>{{ parcelNbr }}</strong>"</li>
                            <li>Ranger le produit dans le colis "<strong v-if="parcel">{{ parcelNbr }}</strong>"</li>
                            <li v-if="parcel && parcel.nbr_products >= parcel.type.max_nbr_products">Fermer le colis "<strong>{{ parcelNbr }}</strong>"</li>
                            <li><button @click="goToHome" class="btn btn-success">Nouvelle reprise</button></li>
                        </ol>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                    </div>
                </div>
            </div>
        </div>
    </main-layout>
</template>
<style scoped>
li:not(:last-child) { 
   margin-bottom: 20px;  
}
</style>