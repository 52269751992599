import axios from 'axios'

export default {

    getCSRF() {

        var path = '/sanctum/csrf-cookie'

        return axios.get(path);
    },
    login(username, password) {

        var path = '/api/store/login'
        var data = {
            username: username,
            password: password
        }

        return axios.post(path, data)
    },
    logout() {

        var path = '/api/store/logout'

        return axios.get(path)
    },
    getCurrentUser() {

        var path = '/api/store/user'

        return axios.get(path)
    },
    /*forgotPassword(email) {

        var path = '/api/store/forgot-password'
        var data = {
            email: email
        }

        return axios.post(path, data)
    },
    resetPassword(token, email, password, passwordConfirmation) {

        var path = '/api/store/reset-password'
        var data = {
            token: token,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
            app: 'store'
        }

        return axios.post(path, data)
    },*/
    register(
        email, 
        basketPublicId, 
        lastName, 
        firstName, 
        phoneNumber,
        documentType,
        documentNumber,
        deliveryDate,
        documentCountryCode
    ) {

        let path = '/api/store/user'
        let data = {

            email: email, 
            basketPublicId: basketPublicId, 
            lastName: lastName, 
            firstName: firstName, 
            phoneNumber: phoneNumber,
            documentType: documentType,
            documentNumber: documentNumber,
            deliveryDate: deliveryDate,
            documentCountryCode: documentCountryCode
        }

        return axios.post(path, data)
    }
}
