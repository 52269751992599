<script type="text/ecmascript-6">
    import MainLayout from '@/layouts/MainLayout.vue'

    import basketMixin from '@/mixins/basket'
    import merchantMixin from '@/mixins/merchant'

    import authService from '@/services/auth'
    import orderService from '@/services/order'

    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'

    import ProductCard from '@/components/ProductCard.vue'
    import CancelButton from '@/components/CancelButton.vue'
    import StandardModal from '@/components/StandardModal.vue'

    export default {
        
        mixins: [basketMixin, merchantMixin],
        components: {MainLayout, VuePhoneNumberInput, ProductCard, CancelButton, StandardModal},
        data() {
            return {

                email: '',
                error: null,
                firstName: '',
                lastName: '',
                phoneNumber: null,
                phoneNumberValue: null,
                isPhoneNumberValid: false,
                isLoading: false,
                documentType: null,
                documentNumber: '',
                deliveryDate: '',
                serialNumber: '',
                documentCountryCode: null,
                displayIMEIHelp: null
            }
        },
        created() {

            this.$tracking.pageView('Register', 'basket', {'Basket ID': this.basketId})
        },
        methods: {
            async submitRegister() {

                this.$tracking.logEvent('Click register', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket'})
                this.error = ''

                this.isLoading = true
                let listingPublicId = null

                try {
                    
                    let response = await authService.register(
                            this.email, 
                            this.basket.public_id, 
                            this.lastName, 
                            this.firstName, 
                            this.phoneNumber,
                            this.documentType,
                            this.documentNumber,
                            this.deliveryDate,
                            this.documentCountryCode.countryCode
                    )

                    let user = response.data
                    
                    response = await orderService.create(
                        user.id,
                        this.basket.product_id,
                        this.basket.merchant_user_id,
                        this.basket.merchant_product_img,
                        this.basket.conditions,
                        this.basket.public_id,
                        this.$store.getters.selectedCampaign,
                        this.basket.offer_template != undefined ? this.basket.offer_template.id : null,
                        this.basket.offer_specs != undefined ? this.basket.offer_specs.id : null,
                        this.basket.offer_specs != undefined ? this.basket.offer_specs.specs : null,
                        this.basket.type,
                        this.$store.getters.selectedPaymentType,
                        listingPublicId,
                        this.serialNumber
                    )

                    let order = response.data
                    this.$router.push({name: 'orderTransfer', params: {id: order.public_id}})
                }
                catch (error) {

                    if (error.response.status == 500) {

                        this.error = 'Une erreur est survenue'
                        this.$tracking.logEvent('Register Error', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                    }
                    else {
                        
                        let errors = error.response.data.errors
                        
                        if (Object.prototype.hasOwnProperty.call(errors, 'email') && errors.email.length > 0) {

                            this.error = 'L\'email n\'est pas valide'
                            this.$tracking.logEvent('Register Error', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                        }
                        else if (Object.prototype.hasOwnProperty.call(errors, 'deliveryDate')) {

                            this.error = 'La date n\'est pas valide'
                            this.$tracking.logEvent('Register Error', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                        }
                        else {

                            this.error = 'Une erreur est survenue'
                        }
                    }
                }
                finally {

                    this.isLoading = false
                }
            },
            scrollUp() {

                window.scrollTo(0, 0)
            },
            updatePhoneNumber(data) {

                if (data.isValid)
                    this.$tracking.logEvent('Update phone number', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket'})
                this.phoneNumber = data.e164
                this.isPhoneNumberValid = data.isValid
            },
            cancel() {

                this.$router.push({name: 'home'})
            }
        },
        computed: {

            phoneTranslations() {

                return {

                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone portable',
                    example: 'Exemple :'
                }
            },
            countryCodes() {

                return this.$t('COUNTRY_CODE');
            }
        },
        watch: {

            isBasketLoaded(val) {

                if (val == true) {

                    if (this.$store.getters.selectedPaymentType == undefined) {

                        this.$router.push({name:'basketAcceptOffer', params: {'id': this.basket.public_id}})
                    }
                }
            },
            deliveryDate() {

                if (this.deliveryDate.length == 2) this.deliveryDate = this.deliveryDate + '/'
                if (this.deliveryDate.length == 5) this.deliveryDate = this.deliveryDate + '/'
            }
        }
    }
</script>
<style>
.input-tel__input,.country-selector__input {

    border-color: #dae1e7 !important;
}
</style>
<template>
    <main-layout :currentStep="40">
        <div v-if="this.isBasketLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Informations client</h3>
                        <div class="card shadow-lg my-4">
                            <div class="card-body mx-sm-4">
                                <div v-if="error" class="alert alert-danger" role="alert">
                                    {{error}}
                                </div>
                                <form action="#" @submit.prevent="submitRegister">
                                    <div class="ms-3 mt-4">
                                        <strong>Email</strong>
                                    </div>
                                    <div class="form-group mt-2">
                                        <input class="w-100 form-control" id="email" required type="text" v-model="email" placeholder="Email">
                                    </div>
                                    <div class="ms-3 mt-4">
                                        <strong>Coordonnées</strong>
                                    </div>
                                    <div class="form-group mt-2 row">
                                        <div class="col"><input class="form-control" required type="text" v-model="firstName" placeholder="Prénom"></div>
                                        <div class="col"><input class="form-control" required type="text" v-model="lastName" placeholder="Nom"></div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <vue-phone-number-input :translations="phoneTranslations" v-model="phoneNumberValue" v-on:update="updatePhoneNumber" :preferred-countries="['FR', 'BE', 'DE']" :default-country-code="'FR'" />
                                    </div>
                                    <div class="ms-3 mt-4">
                                        <strong>Pièce d'identité</strong>
                                    </div>
                                    <div class="form-group mt-2">
                                        <div class="form-group mt-2 row">
                                            <div class="col-4">
                                                <select class="form-select" required v-model="documentCountryCode">
                                                    <option :value="null">Pays du document</option>
                                                    <option v-for="countryCode in countryCodes" :key="countryCode.countryCode" :value="countryCode">{{ countryCode.name }}</option>
                                                </select>
                                            </div>
                                            <div class="col-8">
                                                <select class="form-select col-8" required v-model="documentType">
                                                    <option :value="null">Type de document</option>
                                                    <option value="identity_card">Carte d'identité</option>
                                                    <option value="passport">Passeport</option>
                                                    <option value="driver_licence">Permis de conduire</option>
                                                    <option value="resident_permit">Carte de séjour</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group mt-2 row">
                                            <div class="col"><input class="form-control" required type="text" v-model="documentNumber" placeholder="Numéro de document"></div>
                                            <div class="col"><input class="form-control" required type="text" v-model="deliveryDate" placeholder="date de délivrance (22/01/2020)"></div>
                                        </div>
                                    </div>
                                    <div class="ms-3 mt-4">
                                        <strong>Produit</strong>
                                    </div>
                                    <div class="form-group mt-2 row">
                                        <div class="col-11"><input class="form-control" required type="text" v-model="serialNumber" placeholder="Numéro de série ou IMEI"></div>
                                        <div class="col-1 text-center" style="cursor:pointer;" @click="displayIMEIHelp = true"><i class="bi bi-question-circle align-middle"></i></div>
                                    </div>
                                    <div v-if="error" class="alert alert-danger mt-4" role="alert">
                                        {{error}}
                                    </div>
                                    <div class="form-group mt-3">
                                        <button :disabled="isLoading" class="btn btn-success w-100" type="submit">
                                            Valider les informations
                                            <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="basket" />
                        <cancel-button @click="cancel" />
                    </div>
                </div>
            </div>
        </div>
        <standard-modal v-if="displayIMEIHelp" :title="'Trouver l\'IMEI'" :closeClass="'btn-success'" :closeLabel="'Fermer'" @close="displayIMEIHelp = null">
            <template v-slot:body>
                 <p v-html="$t('IMEI.' + basket.product_type + '.DESCRIPTION')"></p>
            </template>
        </standard-modal>
    </main-layout>
</template>

