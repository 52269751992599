import Vue from 'vue';

Vue.filter('currency', value => {
  
    if (value == undefined) {

        return '0\u00A0€'
    }

    if (typeof value == 'number') {

        value = value.toString()
    }

    let cur = value.split('.')
    let cents = ''
    if (cur.length > 1 && cur[1] != '00') {

        cents = ',' + (cur[1].length == 2 ? cur[1] : cur[1] + '0')
    }

    return cur[0] + cents + '\u00A0€'
});
