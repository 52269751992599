<script type="text/ecmascript-6">
export default {
    data() {

        return {

            steps : [
                {
                    step: 10,
                    number: 1,
                    name: 'Déclaratif produit'
                },
                {
                    step: 30,
                    number: 2,
                    name: 'Offre de reprise'
                },
                {
                    step: 40,
                    number: 3,
                    name: 'Informations client'
                },
                {
                    step: 50,
                    number: 4,
                    name: 'Carte cadeau'
                },
                {
                    step: 60,
                    number: 5,
                    name: 'Logistique'
                },
            ]
        } 
    },
    props: {
        currentStep: Number
    },
    methods: {
        isCurrent(step) {

            if (step == this.currentStep || (this.currentStep == 10 && step == 20) || (this.currentStep == 20 && step == 10)) 
                return true

            return false    
        },
        isActive(step) {

            if (step <= this.currentStep)
                return true

            return false
        },
    }
}
</script>
<template>
    <div v-if="currentStep > 0" class="steps steps-light">
        <a class="step-item" v-for="step in steps" :key="step.step" :class="{active: isActive(step.step), current: isCurrent(step.step)}">
            <div class="step-progress">
            <span class="step-count">{{step.number}}</span>
            </div> 
            <div class="step-label" :class="{'text-muted': !isActive(step.step) && !isCurrent(step.step), 'text-success': isCurrent(step.step) ||!isCurrent(step.step)}">
                {{step.name}}
            </div>
        </a>
    </div>
</template>
