<script type="text/ecmascript-6">

import MainLayout from '@/layouts/MainLayout.vue'

import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import mediaMixin from '@/mixins/media'

import orderService from '@/services/order'

import AcceptedOffer from '@/components/AcceptedOffer.vue'
import ProductCard from '@/components/ProductCard.vue'

import { PDFDocument } from 'pdf-lib'

export default {

    mixins: [orderMixin, merchantMixin, mediaMixin],
    components: {MainLayout, AcceptedOffer, ProductCard}, 
    data() {

        return {

            isLoading: false,
            isGenerated: false
        }
    },
    created() {

        if (this.$route.query.g == 1) {

            this.isGenerated = 1
        }
    },
    methods: {

        async getCouponPdfs() {

            if (this.isOrderLoaded == false) return

            this.isLoading = true

            try {

                let response = await orderService.getCouponPDFs(this.order.public_id)
                let pdfs = response.data

                const pdfDoc = await PDFDocument.create()
                for (let i = 0; i < pdfs.length; i++) {

                    let document = await PDFDocument.load(pdfs[i])
                    const [page] = await pdfDoc.copyPages(document, [0])
                    pdfDoc.insertPage(i, page)
                }

                let mergedPdf = await pdfDoc.saveAsBase64()
                this.openFile(mergedPdf, 'pdf')
            }
            finally {

                this.isLoading = false
            }
        },
        goToDelivery() {

            this.$router.push({name: 'orderDelivery', params: {orderPublicId: this.order.public_id}})
        },
        async generateCoupon() {

            if (this.isOrderLoaded == false) return

            this.isLoading = true

            try {

                await orderService.createCoupon(this.order.public_id)
                this.isGenerated = true
            }
            finally {

                this.isLoading = false
            }
        },
        refreshAfterGenerated() {

            if (this.$route.query.g == 1) {

                this.$router.go();
            }
            else {

                this.$router.push({name: 'orderCoupon', query: {g: 1, f:1}})
            }
        }
    },
    computed: {

        canPrintCoupon() {

            if (this.isOrderLoaded == false) return false
            if (this.order.has_coupon == false) return false

            let couponDate = new Date(this.order.coupon_date)
            let maxDate = new Date(couponDate.getTime() + 10 * 60000)

            return (new Date()) <= maxDate
        }
    },
    watch: {

        isOrderLoaded() {

            this.$tracking.pageView('Coupon', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
        }
    } 
}
</script>
<template>
    <main-layout :currentStep="50">
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11 mb-4">
                        <h3>Carte cadeau</h3>
                        <ol v-if="!order.has_coupon">
                            <li>Faites effacer tous les comptes du produit ou réinitialiser le produit</li>
                            <li>Vérifiez que les comptes sont bien effacés</li>
                            <li>Prenez possession du produit, le client ne pourra plus le récupérer</li>
                            <li>
                                <button v-if="!isGenerated" :disabled="isLoading" @click="generateCoupon" class="btn btn-info">Générer la carte cadeau <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></button>
                                <span v-else><strong>Veuillez <button @click="refreshAfterGenerated" class="btn btn-info">rafraichir la page</button> dans quelques secondes</strong></span>
                            </li>
                        </ol>
                        <ol v-else>
                            <li>Le client a reçu sa carte cadeau par email</li>
                            <li v-if="canPrintCoupon">
                                Si le client en a besoin : <button :disabled="isLoading" @click="getCouponPdfs" class="btn btn-info">Imprimer la carte cadeau <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></button>
                            </li>
                            <li v-else>Vous ne pouvez plus imprimer la carte cadeau</li>
                            <li><button @click="goToDelivery" class="btn btn-success">Mettre le produit dans un colis</button></li>
                        </ol> 
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                    </div>
                </div>
            </div>
        </div>
    </main-layout>
</template>
<style scoped>
li:not(:last-child) { 
   margin-bottom: 20px;  
}
</style>