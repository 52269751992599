<script type="text/ecmascript-6">

import MainLayout from '@/layouts/MainLayout.vue'

import basketMixin from '@/mixins/basket'
import merchantMixin from '@/mixins/merchant'

import basketService from '@/services/basket'

import ProductCard from '@/components/ProductCard.vue'
import ConditionSummary from '@/components/ConditionSummary.vue'
import OfferVouches from '@/components/OfferVouches.vue'
import MaxBestOffer from '@/components/MaxBestOffer.vue'

export default {

    mixins: [basketMixin, merchantMixin],
    components: {MainLayout, ProductCard, ConditionSummary, MaxBestOffer, OfferVouches}, 
    data() {

        return {

            conditionAccepted: true,
            offerValidated: false,
            needToRefresh: false,
            isLoading: false,
            orderRunning: false,
            selectedCampaign: null,
            selectedPaymentType: 'coupon'
        }
    },
    created() {
      
        this.$store.commit('unsetSelected')
        this.$tracking.pageView('accept offer', 'basket', {'Basket ID': this.basketId})
    },
    watch: {

        isBasketLoaded() {

            if (this.basket != undefined)
                this.validateOffer(this.basket.public_id)
        }
    },
    methods: {
        async validateOffer(basketPublicId) {

            try {

                let response = await basketService.validateOffer(basketPublicId)
                this.basket = response.data
                this.offerValidated = true

                if (this.basketOffer.price == 0 && this.basket.bonus.length > 0) {

                    this.selectedCampaign = this.basket.bonus[0]
                }
            }
            catch (error) {
            
                if (error.request && error.request.status == 419) {

                    this.needToRefresh = true
                    this.$tracking.logEvent('Accept offer error', {'Basket ID': basketPublicId, 'Page Name': 'accept offer', 'Page Category': 'basket', 'Error': 'Need to refresh'})
                }
                else if (error.response.data.message == 'CONDITION_NOT_ACCEPTED') {

                    this.$tracking.logEvent('Accept offer error', {'Basket ID': basketPublicId, 'Page Name': 'accept offer', 'Page Category': 'basket', 'Error': 'Condition not accepted'})
                    this.conditionAccepted = false
                }
            }
        },
        acceptOffer() {

            this.$router.push({name: 'basketUser', params: {id: this.basket.public_id}})
        },
        vouchSelected(payload) {

            this.selectedCampaign = payload.campaign
            this.selectedPaymentType = payload.type
            this.$store.commit('setSelectedCampaign', this.selectedCampaign)
            this.$store.commit('setSelectedPaymentType', this.selectedPaymentType)
        },
        getTypeLabel(couponName) {

            let label = ''
            if (this.basketOffer.price > 0) {

                label = this.$options.filters.capitalize(couponName)
            }

            if (this.basket.bonus.length > 0) {

                if (label != '') {

                    label = label + ' et'
                }

                if (label == '') {

                    label = 'code de réduction'
                }
                else {

                    label = label + ' code de réduction'
                }
            }

            return label
        },
    },
    computed: {

        isBestOfferLoaded() {

            return this.offerValidated == true && this.basket != null && this.basketOffer != null
        },
        isBestOfferLoadedAndFilled() {

            return this.isBestOfferLoaded && this.basketOffer != undefined
        },
        productConditions() {

            return this.basket.product_conditions
        },
        basketConditions() {

            return this.basket.conditions != null ? JSON.parse(this.basket.conditions) : undefined
        },
        basketOffer() {

            if (this.basket == undefined) return null

            if (this.basket.attached_to == 'specs') return this.basket.offer_specs

            return this.basket.product_offer
        },
    },
    mounted() {

        window.scrollTo(0,0)
    }
}
</script>
<template>
    <main-layout :currentStep="30">
        <div v-if="this.basket">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Offre de reprise</h3>
                        <div v-if="isBestOfferLoaded">
                            <p>Choisissez l'offre de reprise pour le produit.<br/><span v-if="basket.bonus.length > 0">En ce moment, le client peut bénéficier d’un <strong>BONUS REPRISE</strong> pour un nouvel achat !</span></p>
                            <offer-vouches @vouchSelected="vouchSelected"
                                :merchant="merchant"
                                :offer="basketOffer"
                                :bonus="basket.bonus"
                            />
                            <div class="row">
                                <div class="col-md-7 mb-2 pe-md-0 pe-3">
                                    <button :disabled="isLoading" class="btn btn-success shadow-lg text-white w-100 text-center my-4" @click="acceptOffer">
                                        OFFRE ACCEPTEE PAR LE CLIENT
                                        <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                            <div v-if="orderRunning" class="alert alert-danger mb-4">
                                Une revente pour ce produit est actuellement en cours de processus.
                            </div>
                        </div>
                        <div v-else-if="!isBestOfferLoaded && !conditionAccepted">
                            <div class="card shadow-lg text-center my-4">
                                <div class="card-body">
                                    <h5 class="card-title"><strong>AUCUNE OFFRE DE REPRISE</strong></h5>
                                    <p>Nous n'avons pas d'offre disponible pour ces conditions de reprise.</p>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!isBestOfferLoaded && needToRefresh" class="card text-center shadow-lg">
                            <div class="card-body">
                                <h5 class="card-title text-danger">ERREUR</h5>
                                <p>Une erreur est survenue.<br/>Merci de recharger la page.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="basket" :bonus="selectedCampaign" />
                        <max-best-offer v-if="isBestOfferLoadedAndFilled" :isMax="false" :maxBestOffer="basketOffer" :merchant="merchant"  :bonus="selectedCampaign" :paymentType="selectedPaymentType"/>
                        <condition-summary :basketId="basketId" :productConditions="basketConditions" :productType="basket.product_type" />
                    </div>
                </div>
            </div>
        </div>
    </main-layout>
</template>
