import Vue from 'vue'
import Vuex from 'vuex'
import authService from '@/services/auth.js'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        authenticated: false,
        user: null,
        selectedCampaign: null,
        selectedPaymentType: null,
    },
    getters: {
        authenticated: state => { return state.authenticated },
        user: state => { return state.user },
        store: state => { return state.user.store },
        merchant: state => { return state.user.store.merchant },
        selectedCampaign: state => { return state.selectedCampaign != undefined ? state.selectedCampaign : localStorage.getItem('selected_campaign')},
        selectedPaymentType: state => { return state.selectedPaymentType ? state.selectedPaymentType : localStorage.getItem('selected_payment_type')},
    },
    mutations: {
        setAuthenticated (state, value) {
            
            state.authenticated = value
        },
        setUser (state, value) {
            
            state.user = value
        },
        setSelectedCampaign (state, campaign) {

            state.selectedCampaign = campaign
            localStorage.setItem('selected_campaign', campaign)
        },
        setSelectedPaymentType (state, paymentType) {

            state.selectedPaymentType = paymentType
            localStorage.setItem('selected_payment_type', paymentType)
        },
        unsetSelected(state) {

            state.selectedCampaign = null
            state.selectedPaymentType = null
            localStorage.setItem('selected_campaign', null)
            localStorage.setItem('selected_payment_type', null)
        }
    },
    actions: {
        async getCSRF() {

            await authService.getCSRF()
        },
        async login({ dispatch }, credentials) {

            let response = await authService.getCSRF()
            if (response) {
            
                await authService.login(credentials.username, credentials.password)
            }

            return dispatch('me')
        },
        async logout ({ dispatch }) {

            await authService.logout()
    
            return dispatch('me')
        },
        async me ({ commit }) {
            
            try {

                let response = await authService.getCurrentUser()

                commit('setAuthenticated', true)
                commit('setUser', response.data)

                this._vm.$tracking.setUserId(response.data.id)
                this._vm.$tracking.setUserProperties({'Store ID': response.data.store.id})
            }
            catch {

                commit('setAuthenticated', false)
                commit('setUser', null)
            }
        }
    }
})
