<script type="text/ecmascript-6">

import OfferVouchCash from '@/components/OfferVouchCash.vue'
import OfferVouchBonus from '@/components/OfferVouchBonus.vue'
import OfferVouch from '@/components/OfferVouch.vue'

export default {
    components: {OfferVouch, OfferVouchBonus, OfferVouchCash},
    props: {
        'merchant': {
            'type': Object,
            'required': true
        },
        'bonus': {
            'type': Array,
            'required': true
        },
        'offer': {
            'type': Object,
            'required': true
        },
    },
    methods: {

        vouchSelected(selectedVouch) {

            this.$emit('vouchSelected', selectedVouch)
        },
        updateCampaign(campaign) {

            this.$emit('updateCampaign', campaign)
        },
        isDefaultSelected(type) {

            if (type == 'coupon' && this.hasCoupon) return true;

            if (this.hasCoupon) return false;

            if (type == 'cash' && this.hasCash) return true;

            return false;
        }
    },
    computed: {

        hasCash() {

            if (this.offer == undefined) return false

            if (this.offer.price <= 0) return false

            if (this.merchant == undefined) return false

            if (this.merchant.has_cash == false) return false

            return true
        },
        hasCoupon() {

            if (this.offer == undefined) return false

            if (this.offer.price <= 0) return false

            if (this.merchant == undefined) return false

            if (this.merchant.coupon_type == undefined) return false

            return true
        },
        hasBonus() {

            if (this.bonus == undefined) return false

            if (this.bonus.length == 0) return false

            return true;
        }
    }
}
</script>

<template>
    <div>
        <offer-vouch v-if="hasCoupon" @vouchSelected="vouchSelected"
            :maxBestOffer="offer" 
            :merchant="merchant"
            :hasBonus="hasBonus"
            :isSelected="isDefaultSelected('coupon')"
        />
        <div v-for="campaign in bonus" :key="campaign.id">
            <offer-vouch-bonus @vouchSelected="vouchSelected"
                :maxBestOffer="offer" 
                :merchant="merchant" 
                :bonus="campaign" 
            />
        </div>
        <offer-vouch-cash v-if="hasCash" @vouchSelected="vouchSelected"
            :price="offer.price"
            :hasBonus="hasBonus" 
            :couponValidityPeriod="merchant.coupon_validity_period" 
            :couponValidityProducts="merchant.coupon_validity_products" 
            :couponValidityPlaces="merchant.coupon_validity_places"
            :isSelected="isDefaultSelected('cash')" 
        />
    </div>
</template>