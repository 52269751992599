import store from '@/store/index.js'

export default {

    ifAuthenticated(from, to, next) {

        if (store.getters.authenticated) {

            next()
        }
        else {
            
            store.dispatch('me').then(() => {

                if (store.getters.authenticated) {

                    next()
                }
                else {

                    next({name: 'login', query: {return: from.fullPath}})
                }
            })
        }
    },
    ifNotAuthenticated(to, from, next) {
        
        if (!store.getters.authenticated) {

            next()
        }
        else {
            
            next({name: 'home'})
        }
    }
}
