<script type="text/ecmascript-6">

import MainLayout from '@/layouts/MainLayout.vue'

import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'

import AcceptedOffer from '@/components/AcceptedOffer.vue'
import ProductCard from '@/components/ProductCard.vue'
import CancelButton from '@/components/CancelButton.vue'

import orderService from '@/services/order'

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';


export default {

    components: {MainLayout, AcceptedOffer, VuePhoneNumberInput, ProductCard, CancelButton}, 
    mixins: [orderMixin, merchantMixin],
    data() {
        return {

            yousignMemberId: null,
            phoneNumber: null,
            phoneNumberValue: null,
            isPhoneNumberValid: false,
            isDisplayWaitMessage: false,
            isLoading: false,
            yousignSignatureLink: null
        }
    },
    methods: {
        sleep(ms) {
            
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        nextStep() {

            this.$router.push({name: 'orderCoupon', params: {id: this.order.public_id}})
        },
        async makeTransfer() {

            this.isLoading = true
            let response = await orderService.createTransfer(this.order.public_id, this.phoneNumber)
            if (response.data.version === '3') {

                await this.sleep(2000) // Do that otherwise yousign can failed :(

                this.yousignSignatureLink = response.data.signature_link
                this.initializeYouSign()
                this.error = null
            }
            else if (response.data.version === '2') {

                this.yousignMemberId = response.data.member_id
            }
            this.isLoading = false
        },
        updatePhoneNumber(data) {

            this.phoneNumber = data.e164
            this.isPhoneNumberValid = data.isValid
        },
        getYousignUrl(yousignMemberId) {

            return 'https://'+process.env.VUE_APP_YOUSIGN_DOMAIN+'/procedure/sign?signatureUi='+process.env.VUE_APP_YOUSIGN_TRANSFER_SIGNATURE_UI+'&members=' + yousignMemberId
        },
        async cancelOrder() {

            try {
                
                this.$tracking.logEvent('Cancel Order Clicked', {'Order ID': this.orderId, 'Page Name': 'choose coupons type'})

                await orderService.cancel(this.orderId);
                this.$router.push({name: 'home'})
            }
            catch (error) {

                console.log(error);
            }
        },
        async initializeYouSign() {

            const { default: Yousign } = await import('../utils/Yousign.js');

            const yousign = new Yousign({
                signatureLink: this.yousignSignatureLink,
                iframeContainerId: 'iframe-container',
                isSandbox: process.env.VUE_APP_ENVIRONMENT === 'production' ? false : true,
            });

            this.addYouSignEventListeners(yousign);
        },
        addYouSignEventListeners(yousign) {
            if (yousign) {
                
                yousign.onSuccess(() => {

                    this.nextStep()
                });
            }
        }
    },
    computed: {

        isYousignMemberIdReady() {

            return this.yousignMemberId != null
        },
        phoneTranslations() {

            return {

                countrySelectorLabel: 'Code pays',
                countrySelectorError: 'Choisir un pays',
                phoneNumberLabel: 'Numéro de téléphone portable',
                example: 'Exemple :'
            }
        }
    },
    watch: {

        order() {

            if (this.order != undefined && this.order.user != undefined) this.phoneNumberValue = this.order.user.phone_number
        },
        isOrderLoaded() {

            this.$tracking.pageView('Transfer', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
        }
    }
}
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
<template>
    <main-layout :currentStep="40">
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Bon de cession</h3>
                        <p>Faire signer le bon de cession par le client.<br/>Celui ci va recevoir un code par SMS.</p>
                        <p style="max-width:400px"><vue-phone-number-input :translations="phoneTranslations" v-model="phoneNumberValue" @update="updatePhoneNumber" :preferred-countries="['FR', 'BE', 'DE']" :default-country-code="'FR'" /></p>
                        <button :disabled="!isPhoneNumberValid || isLoading" class="btn btn-success mb-4" type="button" @click="makeTransfer">Faire signer le bon de cession <span v-if="isLoading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span></button>
                        <div class="mb-4" v-if="isDisplayWaitMessage">
                            <div class="alert alert-info" role="alert">
                                Si vous avez déjà signé le bon de cession, un délai de quelques secondes doit être pris en compte.
                            </div>
                            <router-link :to="{name: 'orderDelivery'}" class="btn btn-success">Passer aux informations d'expédition</router-link>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                        <cancel-button @click="cancelOrder" />
                    </div>
                </div>
            </div>
        </div>
        <transition v-if="isYousignMemberIdReady" name="modal">
            <div class="modal-mask">
                <div class="modal modal-wrapper" style="overflow-y: auto">
                    <div class="h-100 modal-dialog modal-lg" role="document">
                        <div class="h-100 modal-content"> 
                            <div class="modal-header">
                                <button @click="nextStep" type="button" class="btn btn-secondary float-right ml-auto" data-dismiss="modal">Fermer</button>
                            </div>
                            <div class="h-100 modal-body">
                                <div class="h-100 embed-responsive embed-responsive-1by1">
                                    <iframe class="h-100 embed-responsive-item" :src="getYousignUrl(yousignMemberId)"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <transition v-if="yousignSignatureLink" name="modalV3">
            <div class="modal-mask">
                <div class="modal modal-wrapper" style="overflow-y: auto">
                    <div class="h-100 modal-dialog modal-lg" role="document">
                        <div class="h-100 modal-content"> 
                            <div class="modal-header">
                                <button @click="yousignSignatureLink = null" type="button" class="btn btn-secondary float-right ml-auto" data-dismiss="modal">Fermer</button>
                            </div>
                            <div class="h-100 modal-body">
                                <div class="h-100" id="iframe-container"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </main-layout>
</template>
<style>
#yousign-iframe {

    height:100%;
    width:100%;
}
</style>