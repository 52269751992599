<script type="text/ecmascript-6">

import MainLayout from '@/layouts/MainLayout.vue'

import basketMixin from '@/mixins/basket'
import merchantMixin from '@/mixins/merchant'

import basketService from '@/services/basket'

import ProductCard from '@/components/ProductCard.vue'
import MaxBestOffer from '@/components/MaxBestOffer.vue'
import BasketConditions from '@/components/BasketConditions.vue'
import StandardModal from '@/components/StandardModal.vue'
import CancelButton from '@/components/CancelButton.vue'

export default {

    mixins: [basketMixin, merchantMixin],
    components: {MainLayout, ProductCard, MaxBestOffer, BasketConditions, StandardModal, CancelButton}, 

    data() {

        return {

            isLoading: false,
            isError: false,
            maxBestOffer: null,
            alertCondition: null
        }
    },
    created() {

        this.$tracking.pageView('basket', 'basket', {'Basket ID': this.basketId})
    },
    watch: {

        isBasketLoaded(val) {

            if (val == true)
                this.loadMaxBestOffer(this.basket)
        }
    },
    computed: {

        isMaxBestOfferLoaded() {

            return this.maxBestOffer != null
        },
        productType() {

            return this.basket.product_type;
        },
        productConditions() {

            let conditions = this.basket.product_conditions

            if (['SMARTPHONE_APPLE', 'SMARTPHONE_OTHER'].includes(this.productType)) {

                conditions[Object.keys(conditions).length + 1] = {
                    'name': 'IS_BATTERY_OK',
                    'type': 'boolean'
                }
            }

            return conditions
        },
        basketConditions() {

            return this.basket.conditions != null ? JSON.parse(this.basket.conditions) : undefined
        },
        canValidate() {

            if (this.basketConditions == undefined) return false

            let conditions = Object.values(this.basket.product_conditions)
            for (let i = 0; i < conditions.length; i++) {
                
                let condition = conditions[i]
                if (this.basketConditions[condition.name] == undefined) {

                    return false
                }

                if (condition.answer != undefined) {

                    if (condition.answer != this.basketConditions[condition.name]) {

                        return false
                    }
                }
            }

            return true
        }
    },
    methods: {

        async updateCondition(basketCondition, event) {

            let condition = {}
            condition[basketCondition.name] = basketCondition.val
            
            let response

            try {

                response = await basketService.updateCondition(this.basket.public_id, condition)
            }
            catch(error) {

                if (error.response.status == 404) {

                    this.$router.push({name: 'home'})
                }

                if (error.response.status == 422) {

                    this.alertCondition = basketCondition
                    event.target.checked = false
                }
            }

            if (response.data == 1) {

                this.updateBasketCondition(basketCondition)
            }
            else {

                this.$router.push({name: 'home'})
            }
        },
        async loadMaxBestOffer(basket) {

            if (this.basket != undefined && this.maxBestOffer == undefined) {

                const response = await basketService.getMaxBestOffer(basket.public_id)
                const infos = response.data
                this.maxBestOffer = {price: infos.max_best_offer_price, currency: infos.max_best_offer_currency, maxBonus: infos.max_bonus_amount}
                this.$tracking.logEvent('Get Max Best Offer', {'Basket ID': basket.public_id, 'Max Best Offer Price': infos.max_best_offer_price})
            }
        },
        validate() {

            this.$router.push({name: 'basketAcceptOffer', params: {id: this.basket.public_id}})
        },
        cancel() {

            this.$router.push({name: 'home'})
        }
    }
}
</script>
<style scoped>
.subtitle {
    font-size: 0.6em;
    font-weight: normal;
}
</style>
<template>
    <main-layout :currentStep="10">
        <div v-if="this.basket">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <div class="ps-3">
                            <h3>Etat du produit</h3>
                        </div>
                        <div class="card shadow-lg mt-4">
                            <div class="card-body">
                                <basket-conditions :conditions="productConditions" :productConditions="basketConditions" :productType="basket.product_type" @updateCondition="updateCondition"/>
                            </div>
                        </div>
                        <button :disabled="!canValidate" class="btn btn-success shadow-lg text-white w-100 text-center mt-3" @click="validate">VOIR L'OFFRE</button>
                        <span v-if="!canValidate" class="d-flex justify-content-center mt-2 text-gd-faint small">Finalisez le déclaratif afin d'obtenir l'offre</span>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="basket" />
                        <max-best-offer v-if="maxBestOffer != undefined" :maxBestOffer="maxBestOffer" :merchant="merchant" :maxBonus="maxBestOffer.maxBonus"/>
                        <cancel-button @click="cancel" />
                    </div>
                </div>
            </div>
        </div>
        <standard-modal v-if="alertCondition" :title="'Une erreur est survenue'" :closeClass="'btn-success'" :closeLabel="'Fermer'" @close="alertCondition = null">
            <template v-slot:body>
                 <p v-html="$t('BASKET_CONDITION.' + alertCondition.name + '.' + basket.product_type + '.ALERT')"></p>
            </template>
        </standard-modal>
    </main-layout>
</template>
