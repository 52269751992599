<script type="text/ecmascript-6">

export default {
    mounted() {

        let crisp = document.createElement('script')
        let codeCrisp = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a66ffddb-f24e-4081-baef-54b8e183fd2a";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();'
        crisp.appendChild(document.createTextNode(codeCrisp))
        document.body.appendChild(crisp)
    },
    computed: {

        currentYear() {

            return new Date().getFullYear()
        }
    }
}
</script>
<template>
    <footer class="mt-2 mt-md-0">
        <div class="container">
            
        </div>
    </footer>
</template>
