import axios from 'axios'

export default {
    create(
        userId, 
        productId, 
        merchantUserId, 
        merchantProductImg, 
        conditions,
        basketPublicId,
        brandCampaignPublicId,
        offerTemplateId,
        specsId,
        specs,
        basketType,
        paymentType,
        listingPublicId,
        serialNumber
    ) {
        let path = '/api/store/orders'
        let data = {
            userId: userId, 
            productId: productId, 
            merchantUserId: merchantUserId, 
            merchantProductImg: merchantProductImg, 
            conditions: conditions,
            basketPublicId: basketPublicId,
            brandCampaignPublicId: brandCampaignPublicId,
            offerTemplateId: offerTemplateId,
            specsId: specsId,
            specs: specs,
            basketType: basketType,
            paymentType: paymentType,
            listingPublicId: listingPublicId,
            serialNumber: serialNumber
        }

        return axios.post(path, data)
    },
    get(orderPublicId) {

        let path = '/api/store/orders/' + orderPublicId
        
        return axios.get(path);
    },
    createTransfer(orderPublicId, phoneNumber) {

        let path = '/api/store/orders/' + orderPublicId + '/transfer'
        let data = {
            phoneNumber: phoneNumber
        }

        return axios.post(path, data);
    },
    createCoupon(orderPublicId) {

        let path = '/api/store/orders/' + orderPublicId + '/coupon';

        return axios.post(path);
    },
    getCouponPDFs(orderPublicId) {

        let path = '/api/store/orders/' + orderPublicId + '/coupon';

        return axios.get(path);
    },
    getDeliveryNote(orderPublicId) {

        let path = '/api/store/orders/' + orderPublicId + '/delivery';

        return axios.get(path);
    },
    createOrderParcel(orderPublicId) {

        let path = '/api/store/orders/' + orderPublicId + '/parcel';

        return axios.post(path);
    },
    listByStore(status) {

        let path = '/api/store/orders'
        let statusesQuery = 'status=' + status
        path = path + '?' + statusesQuery

        return axios.get(path);
    },
    cancel(orderPublicId) {

        let path = '/api/store/orders/' + orderPublicId;

        return axios.delete(path);
    }
}
