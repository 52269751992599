<script>

import orderServices from '@/services/order.js'
import MainLayout from '@/layouts/MainLayout.vue'

export default {
    components: {MainLayout},
    data() {

        return {
            orders: null,
            status: 'filling'
        }
    },
    methods: {

        async listOrdersByStore() {

            var response = await orderServices.listByStore(this.status)
            this.orders = response.data
        }
    },
    mounted() {

        this.listOrdersByStore()
        this.$tracking.pageView('Orders List', 'Dashboard')
    },
    watch: {

        status() {

            this.listOrdersByStore()
        }
    }
}
</script>

<template>
    <main-layout>
        <div class="row mt-3 mt-md-5">
            <h1>Liste des reprises</h1>
            <div class="mt-2 mb-2 row form-group">
                <label class="col-1 col-form-label">Statut :</label>
                <div class="col-3">
                    <select v-model="status" class="form-control">
                        <option value="filling">En cours</option>
                        <option value="to_send">Prochain enlévement</option>
                    </select>
                </div>
            </div>
            <table class="table table-hover font-size-sm" >
                <thead>
                    <tr>
                        <th>Réference</th>
                        <th>Date</th>
                        <th class="col-6">Label</th>
                        <th>Numéro de colis</th>
                        <th>Statut</th>

                    </tr>
                </thead>
                <tbody >
                    <tr v-for="(order, index) in orders" v-bind:key="index">
                        <td>{{ order.public_id }}</td>
                        <td>{{ new Date(order.created_at).toLocaleString('fr-FR',{day: '2-digit', month: '2-digit', year: '2-digit'}) }}</td>
                        <td>{{ order.label }}</td>
                        <td>{{ order.pickup_nbr }} - {{ order.store_parcel_nbr }}</td>
                        <td v-html="$t('STATUS.STATUS_' + order.order_status_id)"></td>
                    </tr>
                </tbody>
                </table>
        </div>
    </main-layout>  
</template>

<style>

</style>
