import Vue from 'vue'
import VueRouter from 'vue-router'

import authGuard from '@/router/guards/auth.js'

import AuthLogin from '@/views/auth/AuthLogin.vue'
import SearchForm from '@/views/SearchForm.vue'
import BasketInfo from '@/views/BasketInfo.vue'
import StoreHome from '@/views/StoreHome.vue'
import AcceptOffer from '@/views/AcceptOffer.vue'
import BasketUser from '@/views/BasketUser.vue'
import OrderTransfer from '@/views/OrderTransfer.vue'
import OrderCoupon from '@/views/OrderCoupon.vue'
import OrderDelivery from '@/views/OrderDelivery.vue'
import OrdersList from '@/views/OrdersList.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: StoreHome,
        name: 'home',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/searchForm',
        component: SearchForm,
        name: 'searchForm',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/basket/:id',
        component: BasketInfo,
        name: 'basketInfo',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/basket/:id/acceptOffer',
        name: 'basketAcceptOffer',
        component: AcceptOffer,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/basket/:id/user',
        name: 'basketUser',
        component: BasketUser,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/transfer',
        name: 'orderTransfer',
        component: OrderTransfer,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/coupon',
        name: 'orderCoupon',
        component: OrderCoupon,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/delivery',
        name: 'orderDelivery',
        component: OrderDelivery,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/orders',
        name: 'orders',
        component: OrdersList,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: ForgotPassword
    },
    {
        path: '/login',
        component: AuthLogin,
        name: 'login',
    },
    {
        path: '/resetPassword/:token',
        name: 'resetPassword',
        component: ResetPassword
    },
    { 
        path: "*", 
        redirect: {name: 'home'},
        name: 'pageNotFound'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
