import axios from 'axios'

export default {

    getBasket(basketPublicId) {

        var path = '/api/store/baskets/' + basketPublicId

        return axios.get(path)
    },
    createFromSearchForm(uid, pid, oid, sid, img) {

        let path = '/api/store/searchFormBasket'
        let data = {
            uid: uid,
            pid: pid,
            oid: oid,
            img: img,
            sid: sid,
        }

        return axios.post(path, data)
    },
    getMaxBestOffer(basketPublicId) {

        let path = '/api/store/baskets/' + basketPublicId + '/maxBestOffer';  

        return axios.get(path)
    },
    updateCondition(basketPublicId, conditions) {

        var path = '/api/store/baskets/' + basketPublicId + '/conditions'

        return axios.patch(path, conditions)
    },
    validateOffer(basketPublicId) {

        var path = '/api/store/baskets/' + basketPublicId + '/offer'

        return axios.patch(path)
    }
}

