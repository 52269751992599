<script type="text/ecmascript-6">
export default {
  methods: {

    emitClick() {

        this.$emit('click')
    }
  }
}
</script>

<template>
    <div class="card-body pt-2">
        <hr class="card-separator"/>
        <div class="mt-3 justify-content-center align-items-center d-flex">
            <button type="button" @click="emitClick()" class="btn btn-sm btn-outline-danger border-lg">Annuler la reprise</button>
        </div>
    </div>
</template>
