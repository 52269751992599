import basketService from '@/services/basket'

export default {

    data() {

        return {

            basket: null
        }
    },
    created() {

        this.loadBasket(this.$route.params.id)
    },
    computed: {

        basketId() {

            return this.$route.params.id
        },
        isBasketLoaded() {

            return this.basket != undefined
        }
    },
    methods: {

        async loadBasket(basketId) {

            let response

            try {
                
                response = await basketService.getBasket(basketId)
            }
            catch (error) {

                this.$router.push({name: 'home'})
            }

            this.basket = response.data
            if (this.basket.order_public_id != undefined) {

                this.$router.push({name: 'orderCoupon', params:{id: this.basket.order_public_id}})
            }
        },
        getConditionLabel(val, productType) {

            return this.$t('BASKET_CONDITION_LABEL.' + productType + '.' + val)
        },
        updateBasketCondition (condition) {

            let conditions = JSON.parse(this.basket.conditions)
            if (conditions == null)
                conditions = {}
            conditions[condition.name] = condition.val
            this.basket.conditions = JSON.stringify(conditions)
        }
    }
}
