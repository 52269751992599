import axios from "axios"

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true

axios.interceptors.response.use(

    function(response) {
      
        return response
    },
    function(error) {

        if (error.response && error.response.status === 401) {

            if (window.location.pathname != '/login')
                window.location = '/login'
        }

        return Promise.reject(error)
    }
)
