<script type="text/ecmascript-6">
export default {
    props: {
        'maxBestOffer': Object, 
        'merchant': Object,
        'isMax': {
            type: Boolean,
            default: true
        },
        'bonus': {
            type: Object,
            default: null
        },
        'maxBonus': Number,
        'paymentType': {
            'type': String
        }
    },
    methods: {
        getBonusColor() {

            return this.bonus.color
        },

        getBrandName() {

            return this.bonus.name.toUpperCase()
        },
        getBonusAmount() {

            let value = this.bonus.bonus_amount / 100
            value = this.$options.filters.currency(value.toString())
            return value
        },
        getMaxBonusAmount() {

            let value = this.maxBonus / 100
            value = this.$options.filters.currency(value.toString())
            return value
        }
    },
    computed: {

        paymentTypeData() {

            if (this.merchant == undefined) return ''

            if (this.merchant.coupon_type != undefined) {
            
                return 'coupon'
            }
            
            if (this.merchant.has_cash) {

                return 'cash'
            }

            return ''
        }
    }
}
</script>
<template>
    <div v-if="bonus" class="card-body pb-0">
        <hr class="card-separator"/>
        <div class="row mt-4">
            <div class="col-8" :class="'text-' + getBonusColor()">
                <strong>
                    <span v-if="isMax">Meilleure offre de reprise </span>
                    <span v-if="!isMax">Offre de reprise </span>
                </strong><br/>
            </div>
            <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                <h4 :class="'text-' + getBonusColor()" v-if="maxBestOffer">{{maxBestOffer.price | currency}}*</h4>
            </div>
        </div>
        <div v-if="this.merchant != undefined && this.maxBestOffer.price > 0" class="justify-content-end mt-3">
            <p v-if="paymentTypeData == 'coupon'" class="text-gd-faint font-size-xxs">
                En {{merchant.coupon_name}} {{merchant.coupon_platform_name}}.
            </p>
        </div>
        <hr class="card-separator"/>
        <div class="row mt-4" :class="'text-' + getBonusColor()">
            <div class="col-8">
                <span>
                    <strong>Bonus {{getBrandName()}}</strong><br/>
                </span>
            </div>
            <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                <h4 :class="'text-' + getBonusColor()">+{{getBonusAmount()}}*</h4>
            </div>
        </div>
        <div class="justify-content-end mt-2">
            <p class="text-gd-faint font-size-xxs">
                <strong>Sous la forme de code de réduction {{merchant.coupon_platform_name}}</strong>
                <strong v-html="bonus.legal_application_label"></strong>
            </p>
        </div>
    </div>
    <div v-else class="card-body pb-0">
        <hr class="card-separator"/>
        <div class="row mt-4">
            <div class="col-6">
                <span v-if="isMax">Meilleure offre<br/>de reprise</span>
                <span v-if="!isMax">Offre de reprise</span>
            </div>
            <div class="col-6 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-success" v-if="maxBestOffer">{{maxBestOffer.price | currency}}*</h4>
            </div>
        </div>
        <div v-if="maxBonus != undefined && maxBonus > 0" class="row mt-2">
            <div class="col-5">En ce moment&nbsp;:</div>
            <div class="col-7 text-right">jusqu'à <strong class="text-success">+{{getMaxBonusAmount()}}</strong> de bonus<br/><small style="font-size:60%">(à choisir à l'étape suivante)</small></div>
        </div>
        <div v-if="this.merchant != undefined" class="justify-content-end mt-3">
            <p v-if="paymentTypeData == 'coupon'" class="text-gd-faint font-size-xxs">
                En {{merchant.coupon_name}} {{merchant.coupon_platform_name}}
            </p>
            <p v-else-if="paymentTypeData == 'cash'" class="text-gd-faint font-size-xxs">
                En cash, sous réserve de conformité de votre produit avec votre déclaratif. Une pièce d'identité et un IBAN valides devront être fournis.<br />Argent disponible sous 14 jours après validation.
            </p>
        </div>
    </div>
</template>
