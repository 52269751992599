<script type="text/ecmascript-6">

export default {
    props: ['title', 'closeLabel', 'closeClass'],
    methods: {
        close() {

            this.$emit('close')
        },
        buttonClicked(buttonName) {

            this.$emit('buttonClicked', buttonName)
        }
    }
}

</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal modal-wrapper" style="overflow-y: auto">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-html="title"></h5>
                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" @click="close" />
                        </div>
                        <div class="modal-body">
                            <slot name="body"></slot>
                        </div>
                        <div class="modal-footer">
                            <slot name="footer"></slot>
                            <button type="button" :class="closeClass == null ? 'btn btn-info' : 'btn ' + closeClass" @click="close">{{closeLabel}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
