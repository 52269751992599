export default {

    methods: {

        getMerchantAsset(merchandId, assetName) {

            return process.env.VUE_APP_ASSETS_URL + merchandId + '/' + assetName
        },
        getCategoryAsset(filename) {

            return process.env.VUE_APP_S3_ASSETS_URL + '/img/category/' + filename
        },
        getCategoryAssetGeneric(categoryId, brand) {

            if (brand === 'APPLE') {

                return process.env.VUE_APP_S3_ASSETS_URL + '/img/category/category-' + categoryId + '-APPLE-generic.png'
            }
            
            return process.env.VUE_APP_S3_ASSETS_URL + '/img/category/category-' + categoryId + '-generic.png'
        },
        openFile(content, extension) {

            let a = document.createElement("a")
            document.body.appendChild(a);
            a.style = "display: none"

            let byteCharacters = atob(content)
            let byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            let byteArray = new Uint8Array(byteNumbers)
            let type = ''
            switch (extension.toLowerCase()) {

                case 'pdf':
                    type = 'application/pdf'
                    break
                case 'jpeg':
                case 'jpg':
                    type = 'image/jpeg'
                    break
                case 'png':
                    type = 'image/png'
                    break
                case 'gif':
                    type = 'image/gif'
                    break
            }
            
            let file = new Blob([byteArray], { type: type+';base64' })
            let fileURL = URL.createObjectURL(file)

            a.href = fileURL
            a.setAttribute('target', '_blank')
            a.click();
        }
    }
}
