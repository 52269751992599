export default {
    SEARCH_FORM: {
        QUESTIONS: {
            category: {
                question: 'Quel est le type du produit ?',
                label: 'Type de produit'
            },
            displayed_brand: {
                question: 'Quelle est sa marque ?',
                label: 'Marque',
                help: ''
            },
            family: {
                question: 'Quelle est sa famille ?',
                label: 'Famille',
                help: 'Pour les iPhone et iPad : allez dans Réglages > Général > Informations > Nom du modèle'
            },
            sub_family: {
                question: 'Quelle est sa sous famille ?',
                label: 'Sous famille',
                help: ''
            },
            model: {
                question: 'Quel est son modèle ?',
                label: 'Modèle',
                help: ''
            },
            option: {
                question: 'Quel option possède t\'il ?',
                label: 'Option',
                help: ''
            },
            capacity: {
                question: 'Quelle est sa capacité de stockage ?',
                label: 'Capacité',
                help: 'Pour les iPhone et iPad : allez dans Réglages > Général > Informations > Capacité'
            },
            processor_generation: {
                question: 'Quelle est sa génération de procésseur ?',
                label: 'Génération de processeur'
            },
            gpu: {
                question: 'Quelle est sa carte graphique ?',
                label: 'Carte graphique',
                help: ''
            },
            hard_drive: {
                question: 'Quel est son type de disque dur ?',
                label: 'Type de disque dur',
                help: ''
            },
            ram: {
                question: 'Quelle est sa capacité de la mémoire vive (RAM) ?',
                label: 'Mémoire vive',
                help: ''
            },
            diagonal: {
                question: 'Quelle est sa taille d\'écran ?',
                label: 'Taille de l\'écran',
                help: ''
            },
            processor_family: {
                question: 'Quelle est sa famille de processeur ?',
                label: 'Famille de procésseur',
                help: ''
            },
            display_type: {
                question: 'Quel est son type d\'écran ?',
                label: 'Type d\'écran',
                help: ''
            },
            monitor_diagonal: {
                question: 'Quelle est la taille de votre écran ?',
                label: 'Taille de l\'écran',
                help: ''
            },
            monitor_screen_type: {
                question: 'De quel type est votre écran ?',
                label: 'Type d\'écran',
                help: ''
            },
            monitor_max_resolution: {
                question: 'Quelle est la résolution de votre écran ?',
                label: 'Résolution d\'écran'
            },
            monitor_connectors: {
                question: 'Quelles types de connection à votre écran ?',
                label: 'Type de connections',
                help: ''
            },
            monitor_curve_type: {
                question: 'Votre écran est il incruvé ?',
                label: 'Ecran incurvé',
                help: ''
            },
            monitor_has_speakers: {
                question: 'Votre écran a t il des enceintes intégrées ?',
                label: 'Enceintes intégrées',
                help: ''
            }
        }
    },
    BASKET_CONDITION: {
        IS_WORKING: {
            SMARTPHONE_APPLE: {
                TITLE: 'Est ce que l\'<strong>iPhone</strong> fonctionne normalement ?',
                DESCRIPTION: '<ol><li>Allumer l\'iPhone</li><li>Vérifier que l’affichage ne présente pas de défauts</li><li>Vérifier que l’écran tactile réagit bien</li><li>Prendre une photo et vérifier que la photo est OK</li><li>Tester une sonnerie pour vérifier que le son est OK</li><ol>'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Est ce que le <strong>smartphone</strong> fonctionne normalement ?',
                DESCRIPTION: '<ol><li>Allumer le smartphone</li><li>Vérifier que l’affichage ne présente pas de défauts</li><li>Vérifier que l’écran tactile réagit bien</li><li>Prendre une photo et vérifier que la photo est OK</li><li>Tester une sonnerie pour vérifier que le son est OK</li><ol>'
            },
            LAPTOP: {
                TITLE: 'Est ce que l\'<strong>ordinateur portable</strong> fonctionne normalement ?',
                DESCRIPTION: '<ol><li>Allumer l\'ordinateur</li><li>Vérifier que l’affichage ne présente pas de défauts</li><li>Vérifier que l’écran tactile, si il y en a un, réagit bien</li><li>Tester un son et vérifier que celui ci est OK</li><ol>'
            },
            TABLET_APPLE: {
                TITLE: 'Est ce que l\'<strong>iPad</strong> fonctionne normalement ?',
                DESCRIPTION: '<ol><li>Allumer l\'iPad</li><li>Vérifier que l’affichage ne présente pas de défauts</li><li>Vérifier que l’écran tactile réagit bien</li><li>Prendre une photo et vérifier que la photo est OK</li><li>Tester un son et vérifier que celui ci est OK</li><ol>'
            },
            TABLET_OTHER: {
                TITLE: 'Est ce que la <strong>tablette</strong> fonctionne normalement ?',
                DESCRIPTION: '<ol><li>Allumer la tablette</li><li>Vérifier que l’affichage ne présente pas de défauts</li><li>Vérifier que l’écran tactile réagit bien</li><li>Prendre une photo et vérifier que la photo est OK</li><li>Tester un son et vérifier que celui ci est OK</li><ol>'
            }
        },
        CONDITION: {
            SMARTPHONE_APPLE: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de l\'iPhone',
                DESCRIPTION: ''
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> du smartphone',
                DESCRIPTION: ''
            },
            LAPTOP: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> du ordinateur portable',
                DESCRIPTION: ''
            },
            OTHER: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> du produit',
                DESCRIPTION: ''
            },
            TABLET_APPLE: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de l\'iPad',
                DESCRIPTION: ''
            },
            TABLET_OTHER: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de la tablette',
                DESCRIPTION: ''
            },
            CONSOLE: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de la console',
                DESCRIPTION: ''
            },
            MONITOR: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de l\'écran d\'ordinateur',
                DESCRIPTION: ''
            },
            WATCH_APPLE: {
                TITLE: 'Décrivez l\'aspect de votre Watch',
                DESCRIPTION: ''
            },
            WATCH_OTHER: {
                TITLE: 'Décrivez l\'aspect de votre montre',
                DESCRIPTION: ''
            },
            DESKTOP: {
                TITLE: 'Décrivez l\'aspect de votre ordinateur',
                DESCRIPTION: ''
            }
        },
        IS_PASSWORD_PROTECTED: {
            LAPTOP: {
                TITLE: 'L\'ordinateur portable est-il bloqué par un mot de passe ?',
                DESCRIPTION: 'Si l\'ordinateur est protégé par un mot de passe, me client doit avoir celui ci pour pouvoir le débloquer.',
                ALERT: 'Sans le mot de passe nous ne pouvons pas reprendre l\'ordinateur.'
            }
        },
        IS_RESETED: {
            SMARTPHONE_APPLE: {
                TITLE: 'Le client a t il le mot de passe pour pouvoir <strong>réinitialiser</strong> son iPhone et <strong>désynchroniser</strong> son compte APPLE ?',
                DESCRIPTION: 'Le client doit avoir le mot de passe de son iPhone pour pouvoir le réinitialiser, si ce n\'est déjà fait.<br><br>Si l\'iPhone est bloqué par la fonction localiser mon iPhone, le client doit connaitre ses identifiants iCloud pour la supprimer ("Localiser mon Iphone" : le bouton doit être gris).<br><img src="/img/form/smartphone-apple-IS_RESETED.png" />',
                ALERT: 'Sans le mot de passe nous ne pouvons pas reprendre l\'iPhone.'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Le client a t il le mot de passe pour pouvoir <strong>réinitialiser</strong> son smartphone et <strong>désynchroniser</strong> son compte GOOGLE ?',
                DESCRIPTION: 'Le client doit avoir le mot de passe de son téléphone pour pouvoir le réinitialiser, si ce n\'est déjà fait.<br>Si le smartphone est associé à un compte GOOGLE, le client doit connaitre ses identifiants GOOGLE pour le supprimer.',
                ALERT: 'Sans le mot de passe nous ne pouvons pas reprendre le smartphone.'
            },
            TABLET_APPLE: {
                TITLE: 'Le client a t il le mot de passe pour pouvoir <strong>réinitialiser</strong> son iPad et <strong>désynchroniser</strong> son compte APPLE ?',
                DESCRIPTION: 'Le client doit avoir le mot de passe de son iPad pour pouvoir le réinitialiser, si ce n\'est déjà fait.<br><br>Si l\'iPhone est bloqué par la fonction localiser mon iPhone, le client doit connaitre ses identifiants iCloud pour la supprimer.',
                ALERT: 'Sans le mot de passe nous ne pouvons pas reprendre l\'iPad.'
            },
            TABLET_OTHER: {
                TITLE: 'Le client a t il le mot de passe pour pouvoir <strong>réinitialiser</strong> sa tablette et <strong>désynchroniser</strong> son compte GOOGLE ?',
                DESCRIPTION: 'Le client doit avoir le mot de passe de sa tablette pour pouvoir la réinitialiser, si ce n\'est déjà fait.<br><br>Si la tablette est associé à un compte GOOGLE, le client doit connaitre ses identifiants GOOGLE pour le supprimer.',
                ALERT: 'Sans le mot de passe nous ne pouvons pas reprendre la tablette.'
            }
        },
        IS_GEOLOC_ACTIVATED: {
            SMARTPHONE_APPLE: {
                TITLE: 'Est ce que l\'iPhone est bloqué opérateur ?',
                DESCRIPTION: 'Si l\'iPhone est bloqué opérateur nous ne pouvons pas le reprendre.',
                ALERT: 'Nous ne pouvons pas reprendre l\'iPhone.'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Est ce que le smartphone est bloqué opérateur ?',
                DESCRIPTION: 'Si le smartphone est bloqué opérateur nous ne pouvons pas le reprendre.',
                ALERT: 'Nous ne pouvons pas reprendre le smartphone.'
            },
            TABLET_APPLE: {
                TITLE: 'Est ce que l\'iPad est bloqué opérateur ',
                DESCRIPTION: 'Si l\'iPad est bloqué opérateur nous ne pouvons pas le reprendre.',
                ALERT: 'Nous ne pouvons pas reprendre l\'iPad.'
            },
            TABLET_OTHER: {
                TITLE: 'Est ce que la tablette est bloqué opérateur ?',
                DESCRIPTION: 'Si la tablette est bloqué opérateur nous ne pouvons pas le reprendre.',
                ALERT: 'Nous ne pouvons pas reprendre la tablette.'
            }
        },
        IS_NO_STANDARD_USE: {

        },
        IS_COMPLETE: {
            LAPTOP: {
                TITLE: 'Est ce que le client a le chargeur d\'origine ?',
                DESCRIPTION: ''
            },
        },
        IS_BATTERY_OK: {

            SMARTPHONE_APPLE: {
                TITLE: 'Est ce que la batterie de l\'<strong>iPhone</strong> fonctionne normalement ?',
                DESCRIPTION: '<ol><li>Aller dans Réglages > Batterie</li><li>Regarder "État de la batterie et recharge"</li><li>Si la capacité maximale est de 85% ou plus répondre OUI, sinon NON</li></ol><p><strong>Si vous ne trouvez pas l\'information, merci d\'indiquer NON.</strong></p>'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Est ce que la batterie du <strong>smartphone</strong> fonctionne normalement ?',
                DESCRIPTION: '<ol><li>Ouvrir la fonction téléphone</li><li>Taper le numéro *#0228#</li><li>Regarder l\'information "level block"</li><li>Si "level block" est à 7 ou 8 répondre OUI, sinon NON</li></ol><p><strong>Si vous ne trouvez pas l\'information, merci d\'indiquer NON.</strong></p>'
            },
        }
    },
    BASKET_CONDITION_LABEL: {
        SMARTPHONE_APPLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou la coque",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>coque fissurée, iPhone déformé"
        },
        SMARTPHONE_OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou la coque",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>coque fissurée, smartphone déformé"
        },
        LAPTOP: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, chocs, <br class='d-md-none'>taches sur l'écran ou la châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>châssis fissuré, ordinateur déformé"
        },
        DESKTOP: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure sur le châssis ou l'écran",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures sur le châssis ou l'écran",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes sur le châssis ou l'écran",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, chocs, <br class='d-md-none'>taches sur l'écran ou la châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>châssis fissuré, ordinateur déformé"
        },
        OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        CONSOLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        TABLET_APPLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        TABLET_OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        MONITOR: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>écran sans pixels morts, <br class='d-md-none'>sans tâches sur l'écran, <br>pas de chocs ou de rayures sur les bords de l'écran",
            3: "Bon état - <br class='d-md-none'>2 pixels morts maximum, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de chocs sur les bords de l'écran, <br>légères rayures sur les bords de l'écran",
            4: "Correct - <br class='d-md-none'>2 pixels morts maximum, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de chocs sur les bords de l'écran, <br>nombreuses rayures sur les bords de l'écran",
            5: "Endommagé - <br class='d-md-none'>plus de 2 pixels morts, <br class='d-md-none'>tâches et rayures sur l'écran"
        },
        WATCH_APPLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
        WATCH_OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        }
    },
    COUNTRY_CODE: [
        {name:"France",  countryCode:"FR"},
        {name:"Afghanistan",  countryCode:"AF"},
        {name:"Îles Åland",  countryCode:"AX"},
        {name:"Albanie",  countryCode:"AL"},
        {name:"Algérie",  countryCode:"DZ"},
        {name:"Samoa américaines",  countryCode:"AS"},
        {name:"Andorre",  countryCode:"AD"},
        {name:"Angola",  countryCode:"AO"},
        {name:"Anguilla",  countryCode:"AI"},
        {name:"Antarctique",  countryCode:"AQ"},
        {name:"Antigua-et-Barbuda",  countryCode:"AG"},
        {name:"Argentine",  countryCode:"AR"},
        {name:"Arménie",  countryCode:"AM"},
        {name:"Aruba",  countryCode:"AW"},
        {name:"Australie",  countryCode:"AU"},
        {name:"Autriche",  countryCode:"AT"},
        {name:"Azerbaïdjan",  countryCode:"AZ"},
        {name:"Bahamas",  countryCode:"BS"},
        {name:"Bahreïn",  countryCode:"BH"},
        {name:"Bangladesh",  countryCode:"BD"},
        {name:"Barbade",  countryCode:"BB"},
        {name:"Biélorussie",  countryCode:"BY"},
        {name:"Belgique",  countryCode:"BE"},
        {name:"Belize",  countryCode:"BZ"},
        {name:"Bénin",  countryCode:"BJ"},
        {name:"Bermudes",  countryCode:"BM"},
        {name:"Bhoutan",  countryCode:"BT"},
        {name:"Bolivie",  countryCode:"BO"},
        {name:"Bosnie-Herzégovine",  countryCode:"BA"},
        {name:"Botswana",  countryCode:"BW"},
        {name:"Île Bouvet",  countryCode:"BV"},
        {name:"Brésil",  countryCode:"BR"},
        {name:"British Virgin Islands",  countryCode:"VG"},
        {name:"Territoire britannique de l’Océan Indien",  countryCode:"IO"},
        {name:"Brunei Darussalam",  countryCode:"BN"},
        {name:"Bulgarie",  countryCode:"BG"},
        {name:"Burkina Faso",  countryCode:"BF"},
        {name:"Burundi",  countryCode:"BI"},
        {name:"Cambodge",  countryCode:"KH"},
        {name:"Cameroun",  countryCode:"CM"},
        {name:"Canada",  countryCode:"CA"},
        {name:"Cap-Vert",  countryCode:"CV"},
        {name:"Iles Cayman",  countryCode:"KY"},
        {name:"République centrafricaine",  countryCode:"CF"},
        {name:"Tchad",  countryCode:"TD"},
        {name:"Chili",  countryCode:"CL"},
        {name:"Chine",  countryCode:"CN"},
        {name:"Hong Kong",  countryCode:"HK"},
        {name:"Macao",  countryCode:"MO"},
        {name:"Île Christmas",  countryCode:"CX"},
        {name:"Îles Cocos",  countryCode:"CC"},
        {name:"Colombie",  countryCode:"CO"},
        {name:"Comores",  countryCode:"KM"},
        {name:"République du Congo",  countryCode:"CG"},
        {name:"République démocratique du Congo",  countryCode:"CD"},
        {name:"Îles Cook",  countryCode:"CK"},
        {name:"Costa Rica",  countryCode:"CR"},
        {name:"Côte d’Ivoire",  countryCode:"CI"},
        {name:"Croatie",  countryCode:"HR"},
        {name:"Cuba",  countryCode:"CU"},
        {name:"Chypre",  countryCode:"CY"},
        {name:"République tchèque",  countryCode:"CZ"},
        {name:"Danemark",  countryCode:"DK"},
        {name:"Djibouti",  countryCode:"DJ"},
        {name:"Dominique",  countryCode:"DM"},
        {name:"République dominicaine",  countryCode:"DO"},
        {name:"Équateur",  countryCode:"EC"},
        {name:"Égypte",  countryCode:"EG"},
        {name:"Salvador",  countryCode:"SV"},
        {name:"Guinée équatoriale",  countryCode:"GQ"},
        {name:"Érythrée",  countryCode:"ER"},
        {name:"Estonie",  countryCode:"EE"},
        {name:"Éthiopie",  countryCode:"ET"},
        {name:"Îles Falkland",  countryCode:"FK"},
        {name:"Îles Féroé",  countryCode:"FO"},
        {name:"Fidji",  countryCode:"FJ"},
        {name:"Finlande",  countryCode:"FI"},
        {name:"Guyane française",  countryCode:"GF"},
        {name:"Polynésie française",  countryCode:"PF"},
        {name:"Terres australes et antarctiques françaises",  countryCode:"TF"},
        {name:"Gabon",  countryCode:"GA"},
        {name:"Gambie",  countryCode:"GM"},
        {name:"Géorgie",  countryCode:"GE"},
        {name:"Allemagne",  countryCode:"DE"},
        {name:"Ghana",  countryCode:"GH"},
        {name:"Gibraltar",  countryCode:"GI"},
        {name:"Grèce",  countryCode:"GR"},
        {name:"Groenland",  countryCode:"GL"},
        {name:"Grenade",  countryCode:"GD"},
        {name:"Guadeloupe",  countryCode:"GP"},
        {name:"Guam",  countryCode:"GU"},
        {name:"Guatemala",  countryCode:"GT"},
        {name:"Guernesey",  countryCode:"GG"},
        {name:"Guinée",  countryCode:"GN"},
        {name:"Guinée-Bissau",  countryCode:"GW"},
        {name:"Guyane",  countryCode:"GY"},
        {name:"Haïti",  countryCode:"HT"},
        {name:"Îles Heard-et-MacDonald",  countryCode:"HM"},
        {name:"Saint-Siège (Vatican)",  countryCode:"VA"},
        {name:"Honduras",  countryCode:"HN"},
        {name:"Hongrie",  countryCode:"HU"},
        {name:"Islande",  countryCode:"IS"},
        {name:"Inde",  countryCode:"IN"},
        {name:"Indonésie",  countryCode:"ID"},
        {name:"Iran",  countryCode:"IR"},
        {name:"Irak",  countryCode:"IQ"},
        {name:"Irlande",  countryCode:"IE"},
        {name:"Ile de Man",  countryCode:"IM"},
        {name:"Israël",  countryCode:"IL"},
        {name:"Italie",  countryCode:"IT"},
        {name:"Jamaïque",  countryCode:"JM"},
        {name:"Japon",  countryCode:"JP"},
        {name:"Jersey",  countryCode:"JE"},
        {name:"Jordanie",  countryCode:"JO"},
        {name:"Kazakhstan",  countryCode:"KZ"},
        {name:"Kenya",  countryCode:"KE"},
        {name:"Kiribati",  countryCode:"KI"},
        {name:"Corée du Nord",  countryCode:"KP"},
        {name:"Corée du Sud",  countryCode:"KR"},
        {name:"Koweït",  countryCode:"KW"},
        {name:"Kirghizistan",  countryCode:"KG"},
        {name:"Laos",  countryCode:"LA"},
        {name:"Lettonie",  countryCode:"LV"},
        {name:"Liban",  countryCode:"LB"},
        {name:"Lesotho",  countryCode:"LS"},
        {name:"Libéria",  countryCode:"LR"},
        {name:"Libye",  countryCode:"LY"},
        {name:"Liechtenstein",  countryCode:"LI"},
        {name:"Lituanie",  countryCode:"LT"},
        {name:"Luxembourg",  countryCode:"LU"},
        {name:"Macédoine",  countryCode:"MK"},
        {name:"Madagascar",  countryCode:"MG"},
        {name:"Malawi",  countryCode:"MW"},
        {name:"Malaisie",  countryCode:"MY"},
        {name:"Maldives",  countryCode:"MV"},
        {name:"Mali",  countryCode:"ML"},
        {name:"Malte",  countryCode:"MT"},
        {name:"Îles Marshall",  countryCode:"MH"},
        {name:"Martinique",  countryCode:"MQ"},
        {name:"Mauritanie",  countryCode:"MR"},
        {name:"Maurice",  countryCode:"MU"},
        {name:"Mayotte",  countryCode:"YT"},
        {name:"Mexique",  countryCode:"MX"},
        {name:"Micronésie",  countryCode:"FM"},
        {name:"Moldavie",  countryCode:"MD"},
        {name:"Monaco",  countryCode:"MC"},
        {name:"Mongolie",  countryCode:"MN"},
        {name:"Monténégro",  countryCode:"ME"},
        {name:"Montserrat",  countryCode:"MS"},
        {name:"Maroc",  countryCode:"MA"},
        {name:"Mozambique",  countryCode:"MZ"},
        {name:"Myanmar",  countryCode:"MM"},
        {name:"Namibie",  countryCode:"NA"},
        {name:"Nauru",  countryCode:"NR"},
        {name:"Népal",  countryCode:"NP"},
        {name:"Pays-Bas",  countryCode:"NL"},
        {name:"Nouvelle-Calédonie",  countryCode:"NC"},
        {name:"Nouvelle-Zélande",  countryCode:"NZ"},
        {name:"Nicaragua",  countryCode:"NI"},
        {name:"Niger",  countryCode:"NE"},
        {name:"Nigeria",  countryCode:"NG"},
        {name:"Niue",  countryCode:"NU"},
        {name:"Île Norfolk",  countryCode:"NF"},
        {name:"Îles Mariannes du Nord",  countryCode:"MP"},
        {name:"Norvège",  countryCode:"NO"},
        {name:"Oman",  countryCode:"OM"},
        {name:"Pakistan",  countryCode:"PK"},
        {name:"Palau",  countryCode:"PW"},
        {name:"Palestine",  countryCode:"PS"},
        {name:"Panama",  countryCode:"PA"},
        {name:"Papouasie-Nouvelle-Guinée",  countryCode:"PG"},
        {name:"Paraguay",  countryCode:"PY"},
        {name:"Pérou",  countryCode:"PE"},
        {name:"Philippines",  countryCode:"PH"},
        {name:"Pitcairn",  countryCode:"PN"},
        {name:"Pologne",  countryCode:"PL"},
        {name:"Portugal",  countryCode:"PT"},
        {name:"Puerto Rico",  countryCode:"PR"},
        {name:"Qatar",  countryCode:"QA"},
        {name:"Réunion",  countryCode:"RE"},
        {name:"Roumanie",  countryCode:"RO"},
        {name:"Russie",  countryCode:"RU"},
        {name:"Rwanda",  countryCode:"RW"},
        {name:"Saint-Barthélemy",  countryCode:"BL"},
        {name:"Sainte-Hélène",  countryCode:"SH"},
        {name:"Saint-Kitts-et-Nevis",  countryCode:"KN"},
        {name:"Sainte-Lucie",  countryCode:"LC"},
        {name:"Saint-Martin (partie française)",  countryCode:"MF"},
        {name:"Saint-Martin (partie néerlandaise)",  countryCode:"SX"},
        {name:"Saint-Pierre-et-Miquelon",  countryCode:"PM"},
        {name:"Saint-Vincent-et-les Grenadines",  countryCode:"VC"},
        {name:"Samoa",  countryCode:"WS"},
        {name:"Saint-Marin",  countryCode:"SM"},
        {name:"Sao Tomé-et-Principe",  countryCode:"ST"},
        {name:"Arabie Saoudite",  countryCode:"SA"},
        {name:"Sénégal",  countryCode:"SN"},
        {name:"Serbie",  countryCode:"RS"},
        {name:"Seychelles",  countryCode:"SC"},
        {name:"Sierra Leone",  countryCode:"SL"},
        {name:"Singapour",  countryCode:"SG"},
        {name:"Slovaquie",  countryCode:"SK"},
        {name:"Slovénie",  countryCode:"SI"},
        {name:"Îles Salomon",  countryCode:"SB"},
        {name:"Somalie",  countryCode:"SO"},
        {name:"Afrique du Sud",  countryCode:"ZA"},
        {name:"Géorgie du Sud et les îles Sandwich du Sud",  countryCode:"GS"},
        {name:"Sud-Soudan",  countryCode:"SS"},
        {name:"Espagne",  countryCode:"ES"},
        {name:"Sri Lanka",  countryCode:"LK"},
        {name:"Soudan",  countryCode:"SD"},
        {name:"Suriname",  countryCode:"SR"},
        {name:"Svalbard et Jan Mayen",  countryCode:"SJ"},
        {name:"Eswatini",  countryCode:"SZ"},
        {name:"Suède",  countryCode:"SE"},
        {name:"Suisse",  countryCode:"CH"},
        {name:"Syrie",  countryCode:"SY"},
        {name:"Taiwan",  countryCode:"TW"},
        {name:"Tadjikistan",  countryCode:"TJ"},
        {name:"Tanzanie",  countryCode:"TZ"},
        {name:"Thaïlande",  countryCode:"TH"},
        {name:"Timor-Leste",  countryCode:"TL"},
        {name:"Togo",  countryCode:"TG"},
        {name:"Tokelau",  countryCode:"TK"},
        {name:"Tonga",  countryCode:"TO"},
        {name:"Trinité-et-Tobago",  countryCode:"TT"},
        {name:"Tunisie",  countryCode:"TN"},
        {name:"Turquie",  countryCode:"TR"},
        {name:"Turkménistan",  countryCode:"TM"},
        {name:"Îles Turques-et-Caïques",  countryCode:"TC"},
        {name:"Tuvalu",  countryCode:"TV"},
        {name:"Ouganda",  countryCode:"UG"},
        {name:"Ukraine",  countryCode:"UA"},
        {name:"Émirats Arabes Unis",  countryCode:"AE"},
        {name:"Royaume-Uni",  countryCode:"GB"},
        {name:"États-Unis",  countryCode:"US"},
        {name:"Îles mineures éloignées des États-Unis",  countryCode:"UM"},
        {name:"Uruguay",  countryCode:"UY"},
        {name:"Ouzbékistan",  countryCode:"UZ"},
        {name:"Vanuatu",  countryCode:"VU"},
        {name:"Venezuela",  countryCode:"VE"},
        {name:"Viêt Nam",  countryCode:"VN"},
        {name:"Îles Vierges américaines",  countryCode:"VI"},
        {name:"Wallis-et-Futuna",  countryCode:"WF"},
        {name:"Sahara occidental",  countryCode:"EH"},
        {name:"Yémen",  countryCode:"YE"},
        {name:"Zambie",  countryCode:"ZM"},
        {name:"Zimbabwe",  countryCode:"ZW"}
    ],
    STATUS: {
        STATUS_1: 'A confirmer',
        STATUS_2: 'A expédier',
        STATUS_3: 'Livraison en cours',
        STATUS_4: 'Contrôle du produit<br/>en cours',
        STATUS_5: 'Revente validée',
        STATUS_6: 'Contre offre<br/>à valider',
        STATUS_7: 'Contrôle du Produit<br/>en attente',
        STATUS_8: 'Choisir un type<br/>de bon d\'achat',
        STATUS_9: 'Revente annulée<br/>Produit à récupérer',
        STATUS_10: 'Terminé'
    },
    IMEI: {
        SMARTPHONE_APPLE: {
            DESCRIPTION: 'Taper *#06# comme numéro de téléphone. L\' IMEI va s\'afficher.',
        },
        SMARTPHONE_OTHER: {
            DESCRIPTION: 'Taper *#06# comme numéro de téléphone. L\' IMEI va s\'afficher.',
        },
        LAPTOP: {
            DESCRIPTION: 'Le numéro de série doit se trouver graver sur la coque de l\'ordinateur.',
        },
        TABLET_APPLE: {
            DESCRIPTION: 'Aller dans Réglages > Général, puis touchez Informations.<br><img src="/img/form/tablet-apple-IMEI.png" />',
        },
        TABLET_OTHER: {
            DESCRIPTION: 'Est ce que la <strong>tablette</strong> fonctionne normalement ?',
        }
    }
}
