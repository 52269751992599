<script type="text/ecmascript-6">
import MainLayout from '@/layouts/MainLayout.vue'

export default {

    components: {MainLayout},
    created() {

        this.$tracking.pageView('Home', 'Home')
    }
}
</script>
<template>
    <main-layout>
        <div class="d-flex justify-content-around m-5">
            <router-link :to="{name: 'searchForm'}" class="btn btn-lg btn-info p-5">Nouvelle reprise</router-link>
            <router-link :to="{name: 'orders'}" class="btn btn-lg btn-info p-5">Liste des reprises</router-link>
        </div>
    </main-layout>
</template>
