import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Tracking from './plugins/tracking'
import './plugins/axios'
import Sentry from './plugins/sentry'
import i18n from './lang/index'

import './filters/currency.js'
import './filters/date.js'
import './filters/uuid.js'
import './filters/currencyInteger.js'
import './filters/capitalize.js'

Vue.config.productionTip = false
Vue.use(Sentry)
Vue.use(Tracking)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {

    this.$store.dispatch('getCSRF')
}
}).$mount('#app')
