<script type="text/ecmascript-6">

import MediaMixin from '@/mixins/media.js'
import MerchantMixin from '@/mixins/merchant.js'

import MainBreadcrumbs from '@/layouts/main/MainBreadcrumbs.vue'

export default {
    mixins: [MediaMixin, MerchantMixin],
    props: {
        currentStep: {

            type: Number,
            default: 0
        }
    },
    components: {MainBreadcrumbs},
    methods: {

        logout() {

            this.$store.dispatch('logout')
        }
    },
    computed: {

        logoSrc() {

            return this.getMerchantAsset(this.merchant.id, this.merchant.asset_logo_name)
        }
    }
}

</script>
<template>
    <header id="store-header" class="navbar navbar-expand-lg shadow-lg">
        <div class="row w-100" id="header-container">
            <div class="offset-1 d-flex align-items-center justify-content-center px-0" id="left-section">
                <router-link :to="{name: 'home'}"><img class="img" :src="logoSrc" /></router-link>
            </div>
            <div class="row mt-5" id="middle-section">
                <div>
                    <main-breadcrumbs :currentStep="currentStep" />
                </div>
            </div>
            <div class="px-0" id="right-section">
                <a class="navbar-brand" id="store-logo">
                    <img src="/img/logo-greendid-header.png" class="img-fluid" />
                </a>
                <div class="dropdown d-flex justify-content-center" id="user-section">
                    <button style="background-color:white !important" class="btn btn-sm btn-secondary dropdown-toggle" href="#" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{$store.getters.user.name}}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li><span class="dropdown-header">{{ $store.getters.store.name }}</span></li>
                        <li><a class="dropdown-item" href="#" @click="logout">Se déconnecter</a></li>
                    </ul>
                </div>
                <div id="contact-section"></div>
            </div>
        </div>
    </header>
</template>

<style scoped>

@import '../../../public/css/header.css';

</style>